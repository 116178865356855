<template>
  <div class="all">
    <div class="paddd">
      <div class="flex row-between row-center cus" @click="showHeats2">
        <img src="../../assets/20240301/头盔.svg" class="himg" />
        <div class="txt-16 w-001">Helmet Fit Guide</div>
        <img
          src="../../assets/20230905/CaretDown.svg"
          v-show="!showHeat2"
          class="dimg"
        />
        <img
          src="../../assets/20230905/CaretUp.svg"
          v-show="showHeat2"
          class="dimg"
        />
      </div>
      <div v-show="showHeat2">
        <ol>
          <li>
            <div class="txt-16 m-t-12">
              Photochromic lenses change color only in response to UV light
            </div>
          </li>
          <li>
            <div class="txt-16 m-t-12">
              and may not be compatible with all helmet styles. Please consult
            </div>
          </li>
          <li>
            <div class="txt-16 m-t-12">the guidelines below.</div>
          </li>
        </ol>
        <div class="box">
          <div class="flex row-start">
            <div class="bw-1">
              <img src="../../assets/20240301/Property1.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/Check.svg" class="bimg02" />
                <div>Open Face</div>
              </div>
            </div>
            <div class="bw-1">
              <img src="../../assets/20240301/Property2.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/Check.svg" class="bimg02" />
                <div>3/4 Helmet</div>
              </div>
            </div>
          </div>
          <div class="flex row-start mt-24">
            <div class="bw-1">
              <img src="../../assets/20240301/Property3.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/Check.svg" class="bimg02" />
                <div>Dirt Helmet</div>
              </div>
            </div>
            <div class="bw-1">
              <img src="../../assets/20240301/Property4.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/Check.svg" class="bimg02" />
                <div>Full Face (Visor Up)</div>
              </div>
            </div>
          </div>
          <div class="flex row-start mt-24">
            <div class="bw-1">
              <img src="../../assets/20240301/Property5.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/X.svg" class="bimg02" />
                <div>Full Face (Visor Down)</div>
              </div>
            </div>
            <div class="bw-1">
              <!-- <img src="../../assets/20240301/Property4.svg" class="bimg01" />
              <div class="bw-2 flex row-center">
                <img src="../../assets/20240301/Check.svg" class="bimg02" />
                <div>Full Face (Visor Up)</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { showHeat2: false }
  },
  methods: {
    showHeats2() {
      this.showHeat2 = !this.showHeat2
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .cus {
    cursor: pointer;
  }
  .bottom {
    border-top: 1px solid #303030;
    margin-top: 60px;
  }
}

ol {
  list-style-type: none;
  margin-left: 30px;
  font-size: 16px;
}

.simg01 {
  cursor: pointer;
  display: block;
  opacity: 0.7;
  margin-right: 24px;
}

.simg_active {
  opacity: 1;
}

@media screen and(min-width: 1920px) {
  .content {
    width: 1920px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 270px;
    padding-right: 270px;

    font-family: League Spartan;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .w-2 {
      width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
    }

    .m-t-32 {
      margin-top: 32px;
    }

    .m-l-32 {
      margin-left: 32px;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 27px;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 690px;
    position: sticky;
    width: 820px;
    //position: relative;

    .ptop {
      // position: fixed;
      // top: 100px;
      // width: 820px;
    }
  }

  .circle {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    margin-top: 20px;

    .simg {
      width: 30px;
      margin-top: 2.7px;
      margin-left: 2.5px;
      cursor: pointer;
      display: block;
    }
  }

  .activec {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
  }

  .box02 {
    width: 100%;
    background-color: #fff;
    //opacity: 0.24;
    color: rgba(0, 0, 0, 0.24);
    text-align: center;
    padding: 16px;
    margin-top: 32px;
  }

  .paddd {
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid #fff;
  }

  .w-001 {
    width: 460px;
  }

  .w-002 {
    width: 492px;
  }

  .dimg {
    cursor: pointer;
  }
}
</style>

<style lang="less" scoped>
@media screen and(max-width: 1919px) {
  .content {
    width: 1440px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 30px;
    padding-right: 30px;

    font-family: League Spartan;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .w-2 {
      width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
    }

    .m-t-32 {
      margin-top: 32px;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 27px;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 490px;
    position: sticky;
    width: 820px;
    //position: relative;

    .ptop {
      // position: fixed;
      // top: 100px;
      // width: 820px;
    }
  }

  .circle {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    margin-top: 20px;

    .simg {
      width: 30px;
      margin-top: 2.7px;
      margin-left: 2.5px;
      cursor: pointer;
      display: block;
    }
  }

  .activec {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
  }

  .box02 {
    width: 100%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.24);
    text-align: center;
    padding: 16px;
    margin-top: 32px;
  }

  .paddd {
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid #fff;
  }

  .w-001 {
    width: 460px;
  }

  .w-002 {
    width: 492px;
  }

  .dimg {
    cursor: pointer;
  }
}

.color-07 {
  color: rgba(255, 255, 255, 0.7);
}
</style>

<style lang="less" scoped>
.all {
  .box {
    width: 480px;
    margin: 0 auto;
    font-size: 16px;
    margin-top: 23px;
    .bw-1 {
      width: 240px;
      text-align: center;
      .bimg01 {
        width: 92px;
      }
      .bw-2 {
        width: 240px;
        line-height: 24px;
        text-align: center;
      }
    }
    .mt-24 {
      margin-top: 24px;
    }
  }
}
</style>

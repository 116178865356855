<template>
  <div class="all">
    <PCHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="content">
      <div class="flex row-between row-center t001">
        <div class="w-1">
          <div class="ptop">
            <PCLensesSwipper :colors="colors" :fromIndex="fromIndex" />
          </div>
        </div>
        <div class="w-2">
          <div class="txt-42">{{ $t('message.lenses.replacement') }}</div>
          <div class="txt-21 m-t-8" style="font-family: Oswald">
            {{ $t('message.lenses.money') }}
          </div>
          <div v-show="!ispro">
            <div class="txt-16 m-t-8 color-07">
              {{ $t('message.lenses.sunglasses') }}
            </div>
            <div>
              <ul class="m-t-8 color-07">
                <li>
                  <p class="txt-16">{{ $t('message.lenses.polarization') }}</p>
                </li>
                <li>
                  <p class="txt-16">{{ $t('message.lenses.bayer_level') }}</p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.oil_water') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.anti_fog') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.protection') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.contrast') }}
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div v-show="ispro">
            <div class="txt-16 m-t-8 color-07">
              {{ $t('message.lenses.photochromic_lens') }}
            </div>
            <div>
              <ul class="m-t-8 color-07">
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.light') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.bayer_level') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.oil_water') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.anti_fog') }}
                  </p>
                </li>
                <li>
                  <p class="txt-16">
                    {{ $t('message.lenses.protection') }}
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="txt-27 m-t-32">{{ $t('message.lenses.color') }}</div>
          <div class="txt-16 m-t-8 color-07">
            {{ $t('message.lenses.' + colors) }}
          </div>
          <div class="flex row-betweent ron-center">
            <div
              class="circle"
              :class="[colors == 'Navy_Blue' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Navy_Blue.png"
                class="simg"
                @click="selectColor('Navy_Blue')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Dark_Green' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Dark_Green.png"
                class="simg"
                @click="selectColor('Dark_Green')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Dark_Grey' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Dark_Grey.png"
                class="simg"
                @click="selectColor('Dark_Grey')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Tea_Brown' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Tea_Brown.png"
                class="simg"
                @click="selectColor('Tea_Brown')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Mirrored_Yellow' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Mirrored_Yellow.png"
                class="simg"
                @click="selectColor('Mirrored_Yellow')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Mirrored_Green' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Mirrored_Green.png"
                class="simg"
                @click="selectColor('Mirrored_Green')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Mirrored_Red' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Mirrored_Red.png"
                class="simg"
                @click="selectColor('Mirrored_Red')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Mirrored_Silver' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Mirrored_Silver.png"
                class="simg"
                @click="selectColor('Mirrored_Silver')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Mirrored_Blue' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Mirrored_Blue.png"
                class="simg"
                @click="selectColor('Mirrored_Blue')"
              />
            </div>
            <div class="circle" :class="[colors == 'Black' ? 'activec' : '']">
              <img
                src="../../assets/20230905/mj/Black.png"
                class="simg"
                @click="selectColor('Black')"
              />
            </div>
            <div
              class="circle"
              :class="[colors == 'Photochromic' ? 'activec' : '']"
            >
              <img
                src="../../assets/20230905/mj/Photochromic.png"
                class="simg"
                @click="selectColor('Photochromic')"
              />
            </div>
          </div>

          <div class="box02 txt-21">{{ $t('message.lenses.coming_soon') }}</div>

          <div>
            <Lenses01></Lenses01>
          </div>

          <div class="paddd" style="margin-top: 16px">
            <div class="flex row-between row-center cus" @click="showHeats">
              <img src="../../assets/20230905/HeartStraight.svg" class="himg" />
              <div class="txt-16 w-001">
                {{ $t('message.lenses.care_instructions') }}
              </div>
              <img
                src="../../assets/20230905/CaretDown.svg"
                v-show="!showHeat"
                class="dimg"
              />
              <img
                src="../../assets/20230905/CaretUp.svg"
                v-show="showHeat"
                class="dimg"
              />
            </div>
            <div v-show="showHeat" class="m-l-32">
              <ol>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.gentle_cleaning') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.special_cloth') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.avoid_scratches') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.avoid_high') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.no_saliva') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.regular_inspection') }}
                  </div>
                </li>
                <li>
                  <div class="txt-16 m-t-12">
                    {{ $t('message.lenses.avoid_chemical') }}
                  </div>
                </li>
              </ol>
            </div>
          </div>

          <div class="paddd" style="margin-top: 16px">
            <div class="flex row-start">
              <!-- <img src="../../assets/20230905/Share.svg" class="himg">
              <div class="txt-16 w-002">Share</div> -->
              <img
                src="../../assets/20230905/LinkSimple.svg"
                class="simg01"
                :class="mindex == 0 ? 'simg_active' : ''"
                @click="copyClip"
                @mouseenter="handleMouseEnter(0)"
                @mouseleave="handleMouseLeave(0)"
              />
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews"
                target="_blank"
              >
                <img
                  src="../../assets/20230905/FacebookLogo.svg"
                  class="simg01"
                  :class="mindex == 1 ? 'simg_active' : ''"
                  @mouseenter="handleMouseEnter(1)"
                  @mouseleave="handleMouseLeave(1)"
              /></a>
              <a
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
                target="_blank"
                ><img
                  src="../../assets/20230905/TwitterLogo.svg"
                  class="simg01"
                  :class="mindex == 2 ? 'simg_active' : ''"
                  @mouseenter="handleMouseEnter(2)"
                  @mouseleave="handleMouseLeave(2)"
              /></a>
              <a
                href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews"
                target="_blank"
              >
                <img
                  src="../../assets/20230905/LinkedinLogo.svg"
                  class="simg01"
                  :class="mindex == 3 ? 'simg_active' : ''"
                  @mouseenter="handleMouseEnter(3)"
                  @mouseleave="handleMouseLeave(3)"
              /></a>
              <a
                href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
                target="_blank"
                rel="nofollow"
                ><img
                  src="../../assets/20230905/mail.svg"
                  :class="mindex == 4 ? 'simg_active' : ''"
                  class="simg01"
                  @mouseenter="handleMouseEnter(4)"
                  @mouseleave="handleMouseLeave(4)"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="width: 100%;height: 60px;">

    </div> -->
    <div class="bottom">
      <PCFooter />
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
import clipboard from 'clipboard'
import PCHeader1 from '@/components/header/PCHeader04.vue' //头部
import PCFooter from '@/components/footer/PCFoote01.vue' //底部
import PCLensesSwipper from '@/components/lenses/PCLensesSwipper.vue' //底部
import Lenses01 from './PCLenses01.vue'
export default {
  components: {
    PCHeader1,
    PCFooter,
    PCLensesSwipper,
    Lenses01,
  },
  data() {
    return {
      showHeat: false,
      changeClass1: 1,
      tabIndexs1: 2, //用来传值给子组件,来显示点击了第几个
      colors: 'Navy_Blue',
      fromIndex: 0,
      ispro: false,
      mindex: 100,
    }
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    //console.log('less_stat', this.$route.query.id)
    window.addEventListener('scroll', this.debounce(this.handleScroll, 10))
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      //console.log('top',top);
      if (top < 60) {
        this.changeClass1 = 1
      } else {
        this.changeClass1 = 2
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },
    showHeats() {
      this.showHeat = !this.showHeat
    },
    selectColor(values) {
      this.colors = values
      if (values == 'Photochromic') {
        this.ispro = true
      } else {
        this.ispro = false
      }
    },
    //复制
    copyClip() {
      let text = 'https://blucapsport.com/lenses'
      clipboard.copy(text)
      alert('已复制到剪贴板！')
    },
    handleMouseEnter(sindex) {
      this.mindex = sindex
    },
    handleMouseLeave(sindex) {
      console.log('sindex', sindex)
      this.mindex = 100
    },
    getScroll(event) {
      // 滚动条距离底部的距离scrollBottom
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight
      console.log('bottom', scrollBottom)
      // if (this.finished && scrollBottom < 40) {
      //  操作
      // }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // console.log('this.$route.query.id', this.$route.query)
        if (this.$route.query) {
          var id = this.$route.query.id
          if (id == 1) {
            this.colors = 'Photochromic'
            this.ispro = true
          } else {
            this.colors = 'Navy_Blue'
            this.ispro = false
          }
        }
      },
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;
  .cus {
    cursor: pointer;
  }
  .bottom {
    border-top: 1px solid #303030;
    margin-top: 60px;
  }
}

ol {
  list-style-type: counter;
  margin-left: 16px;
  font-size: 16px;
}

.simg01 {
  cursor: pointer;
  display: block;
  opacity: 0.7;
  margin-right: 24px;
}

.simg_active {
  opacity: 1;
}

@media screen and(min-width: 1920px) {
  .content {
    width: 1920px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 270px;
    padding-right: 270px;

    font-family: League Spartan;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .w-2 {
      width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
    }

    .m-t-32 {
      margin-top: 32px;
    }

    .m-l-32 {
      margin-left: 32px;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 27px;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 690px;
    position: sticky;
    width: 820px;
    //position: relative;

    .ptop {
      // position: fixed;
      // top: 100px;
      // width: 820px;
    }
  }

  .circle {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    margin-top: 20px;

    .simg {
      width: 30px;
      margin-top: 2.7px;
      margin-left: 2.5px;
      cursor: pointer;
      display: block;
    }
  }

  .activec {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
  }

  .box02 {
    width: 100%;
    background-color: #fff;
    //opacity: 0.24;
    color: rgba(0, 0, 0, 0.24);
    text-align: center;
    padding: 16px;
    margin-top: 32px;
  }

  .paddd {
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid #fff;
  }

  .w-001 {
    width: 460px;
  }

  .w-002 {
    width: 492px;
  }

  .dimg {
    cursor: pointer;
  }
}
</style>

<style lang="less" scoped>
@media screen and(max-width: 1919px) {
  .content {
    width: 1440px;
    margin: 0 auto;
    margin-top: 100px;
    padding-left: 30px;
    padding-right: 30px;

    font-family: League Spartan;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
    .t-001 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
    }

    .w-2 {
      width: 520px;
    }

    .txt-42 {
      font-size: 42px;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 27px;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21 {
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 16px;
      font-weight: 400;
      line-height: 110%;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
    }

    .m-t-32 {
      margin-top: 32px;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 27px;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
  }

  .w-1 {
    flex-basis: calc(100% - 520px);
    max-width: calc(100% - 520px);
    padding-right: 60px;
    top: 109px;
    height: calc(100vh - 30%);
    min-height: 490px;
    position: sticky;
    width: 820px;
    //position: relative;

    .ptop {
      // position: fixed;
      // top: 100px;
      // width: 820px;
    }
  }

  .circle {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    margin-top: 20px;

    .simg {
      width: 30px;
      margin-top: 2.7px;
      margin-left: 2.5px;
      cursor: pointer;
      display: block;
    }
  }

  .activec {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
  }

  .box02 {
    width: 100%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.24);
    text-align: center;
    padding: 16px;
    margin-top: 32px;
  }

  .paddd {
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid #fff;
  }

  .w-001 {
    width: 460px;
  }

  .w-002 {
    width: 492px;
  }

  .dimg {
    cursor: pointer;
  }
}

.color-07 {
  color: rgba(255, 255, 255, 0.7);
}
</style>

<template>
  <div class="all">
    <MPHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="top">
      <PCLensesSwipper :colors="colors" :fromIndex="fromIndex" />
    </div>
    <div class="container">
      <div class="txt-42 m-t-30">{{ $t('message.lenses.replacement') }}</div>
      <div class="txt-21-01 m-t-8">{{ $t('message.lenses.money') }}</div>
      <div v-show="!ispro">
        <div class="txt-16 m-t-8 color-07">
          {{ $t('message.lenses.sunglasses') }}
        </div>

        <div class="m-t-8 color-07">
          <ul>
            <li>
              <p class="txt-16">{{ $t('message.lenses.polarization') }}</p>
            </li>
            <li>
              <p class="txt-16">{{ $t('message.lenses.bayer_level') }}</p>
            </li>
            <li>
              <p class="txt-16">{{ $t('message.lenses.oil_water') }}</p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.anti_fog') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.protection') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.contrast') }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div v-show="ispro">
        <div class="txt-16 m-t-8 color-07">
          {{ $t('message.lenses.photochromic_lens') }}
        </div>
        <div>
          <ul class="m-t-8 color-07">
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.light') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.bayer_level') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.oil_water') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.anti_fog') }}
              </p>
            </li>
            <li>
              <p class="txt-16">
                {{ $t('message.lenses.protection') }}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="txt-27 m-t-32">{{ $t('message.lenses.color') }}</div>
      <div class="txt-16 m-t-8 color-07">
        {{ $t('message.lenses.' + colors) }}
      </div>
      <div class="m-t-060 flex row-betweent ron-center height01">
        <div class="circle" :class="[colors == 'Navy_Blue' ? 'activec' : '']">
          <img
            src="../../assets/20230905/mj/Navy_Blue.png"
            class="simg"
            @click="selectColor('Navy_Blue')"
          />
        </div>
        <div class="circle" :class="[colors == 'Dark_Green' ? 'activec' : '']">
          <img
            src="../../assets/20230905/mj/Dark_Green.png"
            class="simg"
            @click="selectColor('Dark_Green')"
          />
        </div>
        <div class="circle" :class="[colors == 'Dark_Grey' ? 'activec' : '']">
          <img
            src="../../assets/20230905/mj/Dark_Grey.png"
            class="simg"
            @click="selectColor('Dark_Grey')"
          />
        </div>
        <div class="circle" :class="[colors == 'Tea_Brown' ? 'activec' : '']">
          <img
            src="../../assets/20230905/mj/Tea_Brown.png"
            class="simg"
            @click="selectColor('Tea_Brown')"
          />
        </div>
        <div
          class="circle"
          :class="[colors == 'Mirrored_Yellow' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Mirrored_Yellow.png"
            class="simg"
            @click="selectColor('Mirrored_Yellow')"
          />
        </div>
        <div
          class="circle"
          :class="[colors == 'Mirrored_Green' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Mirrored_Green.png"
            class="simg"
            @click="selectColor('Mirrored_Green')"
          />
        </div>
      </div>

      <div class="flex row-betweent ron-center height01">
        <div
          class="circle"
          :class="[colors == 'Mirrored_Red' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Mirrored_Red.png"
            class="simg"
            @click="selectColor('Mirrored_Red')"
          />
        </div>
        <div
          class="circle"
          :class="[colors == 'Mirrored_Silver' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Mirrored_Silver.png"
            class="simg"
            @click="selectColor('Mirrored_Silver')"
          />
        </div>
        <div
          class="circle"
          :class="[colors == 'Mirrored_Blue' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Mirrored_Blue.png"
            class="simg"
            @click="selectColor('Mirrored_Blue')"
          />
        </div>
        <div class="circle" :class="[colors == 'Black' ? 'activec' : '']">
          <img
            src="../../assets/20230905/mj/Black.png"
            class="simg"
            @click="selectColor('Black')"
          />
        </div>
        <div
          class="circle"
          :class="[colors == 'Photochromic' ? 'activec' : '']"
        >
          <img
            src="../../assets/20230905/mj/Photochromic.png"
            class="simg"
            @click="selectColor('Photochromic')"
          />
        </div>
      </div>

      <div class="box02 txt-21">{{ $t('message.lenses.coming_soon') }}</div>

      <div class="padBox m-t-32" style="padding-top: 0.16rem">
        <div class="flex row-between row-center" @click="showHeats">
          <img src="../../assets/20230905/HeartStraight.svg" class="himg" />
          <div class="txt-16 w-001">
            {{ $t('message.lenses.care_instructions') }}
          </div>
          <img
            src="../../assets/20230905/CaretDown.svg"
            v-show="!showHeat"
            class="dimg"
          />
          <img
            src="../../assets/20230905/CaretUp.svg"
            v-show="showHeat"
            class="dimg"
          />
        </div>
        <div v-show="showHeat" class="m-l-32">
          <ol>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.gentle_cleaning') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.special_cloth') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.avoid_scratches') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.avoid_high') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.no_saliva') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.regular_inspection') }}
              </div>
            </li>
            <li>
              <div class="txt-16 m-t-12">
                {{ $t('message.lenses.avoid_chemical') }}
              </div>
            </li>
          </ol>
        </div>
      </div>

      <div class="padBox m-t-16">
        <div class="m-t-24 flex row-start">
          <!-- <img src="../../assets/20230905/Share.svg" class="himg">
              <div class="txt-16 w-002">Share</div> -->
          <img
            src="../../assets/20230905/LinkSimple.svg"
            class="simg01"
            :class="mindex == 0 ? 'simg_active' : ''"
            @click="copyClip"
            @mouseenter="handleMouseEnter(0)"
            @mouseleave="handleMouseLeave(0)"
          />
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews"
            target="_blank"
          >
            <img
              src="../../assets/20230905/FacebookLogo.svg"
              class="simg01"
              :class="mindex == 1 ? 'simg_active' : ''"
              @mouseenter="handleMouseEnter(1)"
              @mouseleave="handleMouseLeave(1)"
          /></a>
          <a
            href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
            target="_blank"
            ><img
              src="../../assets/20230905/TwitterLogo.svg"
              class="simg01"
              :class="mindex == 2 ? 'simg_active' : ''"
              @mouseenter="handleMouseEnter(2)"
              @mouseleave="handleMouseLeave(2)"
          /></a>
          <a
            href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews"
            target="_blank"
          >
            <img
              src="../../assets/20230905/LinkedinLogo.svg"
              class="simg01"
              :class="mindex == 3 ? 'simg_active' : ''"
              @mouseenter="handleMouseEnter(3)"
              @mouseleave="handleMouseLeave(3)"
          /></a>
          <a
            href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
            target="_blank"
            rel="nofollow"
            ><img
              src="../../assets/20230905/mail.svg"
              :class="mindex == 4 ? 'simg_active' : ''"
              class="simg01"
              @mouseenter="handleMouseEnter(4)"
              @mouseleave="handleMouseLeave(4)"
          /></a>
        </div>
      </div>
    </div>
    <div class="bot">
      <MPFooter />
    </div>
  </div>
</template>

<script>
import clipboard from 'clipboard'
import MPHeader1 from '@/components/header/MPHeader04.vue' //头部
import MPFooter from '@/components/footer/MPFooter04.vue' //底部
import PCLensesSwipper from '@/components/lenses/PCLensesSwipper.vue' //滚动
export default {
  components: {
    MPHeader1,
    MPFooter,
    PCLensesSwipper,
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 2, //用来传值给子组件,来显示点击了第几个
      showHeat: false,
      colors: 'Navy_Blue',
      fromIndex: 1,
      ispro: false,
      mindex: 100,
    }
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener('scroll', this.debounce(this.handleScroll, 10))
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (top < 60) {
        this.changeClass1 = 1
      } else {
        this.changeClass1 = 2
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },
    showHeats() {
      this.showHeat = !this.showHeat
    },
    selectColor(values) {
      this.colors = values
      if (values == 'Photochromic') {
        this.ispro = true
      } else {
        this.ispro = false
      }
    },
    //复制
    copyClip() {
      let text = 'https://blucapsport.com/lenses'
      clipboard.copy(text)
      alert('已复制到剪贴板！')
    },
    handleMouseEnter(sindex) {
      this.mindex = sindex
    },
    handleMouseLeave(sindex) {
      console.log('sindex', sindex)
      this.mindex = 100
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // console.log('this.$route.query.id', this.$route.query)
        if (this.$route.query) {
          console.log('this.$route.query.id', this.$route.query.id)
          var id = this.$route.query.id
          if (id == 1) {
            this.colors = 'Photochromic'
            this.ispro = true
          } else {
            this.colors = 'Navy_Blue'
            this.ispro = false
          }
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
ol {
  list-style-type: counter;
  margin-left: 0.1rem;
  font-size: 0.16rem;
}

.simg01 {
  cursor: pointer;
  display: block;
  opacity: 0.7;
  margin-right: 0.24rem;
}

.simg_active {
  opacity: 1;
}

.all {
  width: 100%;

  .top {
    width: 100%;
  }

  .container {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-family: League Spartan;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);

    .txt-42 {
      font-size: 0.42rem;
      font-weight: 500;
      line-height: normal;
    }

    .txt-27 {
      font-size: 0.27rem;
      font-weight: 500;
      line-height: 120%;
    }

    .txt-21-01 {
      font-family: Oswald;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-16 {
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 120%;
    }

    .m-t-32 {
      margin-top: 0.32rem;
    }

    .m-t-30 {
      margin-top: 0.3rem;
    }

    .m-t-24 {
      margin-top: 0.24rem;
    }

    .m-t-14 {
      margin-top: 0.14rem;
    }

    .m-t-16 {
      margin-top: 0.16rem;
    }

    .m-t-12 {
      margin-top: 0.12rem;
    }

    .m-t-8 {
      margin-top: 0.08rem;
    }

    .circle {
      margin-right: 0.2rem;
      width: 0.35rem;
      height: 0.35rem;
      margin-top: 0.2rem;

      .simg {
        width: 0.3rem;
        margin-left: 0.025rem;
        margin-top: 0.025rem;
        cursor: pointer;
        display: block;
      }
    }

    .height01 {
      height: 0.4rem;
    }

    .activec {
      width: 0.35rem;
      height: 0.35rem;
      background-color: #fff;
      border-radius: 50%;
    }

    .box02 {
      width: 100%;
      background-color: #fff;
      //opacity: 0.24;
      color: rgba(0, 0, 0, 0.24);
      text-align: center;
      padding: 0.16rem;
      margin-top: 0.32rem;
    }

    .m-l-32 {
      margin-left: 0.32rem;
    }
  }

  .bot {
    border-top: 0.01rem solid #303030;
    margin-top: 0.34rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 0.2rem;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.16rem;
  }

  .padBox {
    border-top: 0.01rem solid #fff;

    .sl {
      width: 1.5rem;
      font-size: 0.16rem;
      background-color: #000;
      color: #fff;
      border: 0.01rem solid #fff;
      height: 0.44rem;
    }

    .w-3 {
      width: 1.5rem;
      margin-top: 0.12rem;
    }

    .box01 {
      width: 100%;
      border: 0.01rem solid #fff;
      padding: 0.14rem 0.16rem;
      margin-top: 0.1rem;
    }

    .w-4 {
      width: 2.9rem;
      margin-left: 0.12rem;
    }

    .box02 {
      width: 100%;
      background-color: #fff;
      //opacity: 0.24;
      color: #000;
      color: rgba(0, 0, 0, 0.24);
      padding: 0.16rem;
      margin-top: 0.32rem;
    }

    .w-001 {
      width: 2.6rem;
    }

    .w-002 {
      width: 2.9rem;
    }
  }

  .color-07 {
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>

<template>
  <div class="all">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in swiperList"
          :key="colors + index"
        >
          <img :src="item" alt class="img" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev" v-show="fromIndex == 0"></div>
      <div class="swiper-button-next" v-show="fromIndex == 0"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
export default {
  props: {
    colors: String,
    fromIndex: Number,
  },
  data() {
    return {
      // 轮播图
      swiperList: [
        require('../../assets/20230905/mj/mj02.png'),
        require('../../assets/20230905/mj/mj12.png'),
        require('../../assets/20230905/mj/mj13.png'),
      ],
    }
  },
  watch: {
    colors(newVal) {
      console.log('colors', newVal)
      this.mySwiper.destroy(true)

      if (newVal == 'Navy_Blue') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj02.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Dark_Green') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj03.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Dark_Grey') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj04.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Tea_Brown') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj05.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Mirrored_Yellow') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj06.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Mirrored_Green') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj07.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Mirrored_Red') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj08.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Mirrored_Silver') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj09.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Mirrored_Blue') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj10.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Photochromic') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj11.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      } else if (newVal == 'Black') {
        this.swiperList = [
          require('../../assets/20230905/mj/mj01.png'),
          require('../../assets/20230905/mj/mj12.png'),
          require('../../assets/20230905/mj/mj13.png'),
        ]
      }

      this.$nextTick(() => {
        this.mySwiper = new Swiper('.swiper-container', {
          pagination: { el: '.swiper-pagination' },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          //loop: true,
          // autoplay: {
          //     disableOnInteraction: false,
          //     delay: 2000,
          // },
        })
      })
    },
    $route: {
      immediate: true,
      handler() {
        // console.log('this.$route.query.id', this.$route.query)
        if (this.$route.query) {
          console.log('this.$route.query.id', this.$route.query.id)
          var id = this.$route.query.id
          if (id == 1) {
            this.swiperList = [
              require('../../assets/20230905/mj/mj11.png'),
              require('../../assets/20230905/mj/mj12.png'),
              require('../../assets/20230905/mj/mj13.png'),
            ]
          }
        }
      },
    },
  },
  // activated() {
  //     this.$nextTick(() => {
  //         this.swiper();
  //     });
  // },
  mounted() {
    // this.$nextTick(() => {
    //     this.swiper();
    // });
    this.mySwiper = new Swiper('.swiper-container', {
      pagination: { el: '.swiper-pagination' },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      //loop: true,
      // autoplay: {
      //     disableOnInteraction: false,
      //     delay: 2000,
      // },
    })
  },

  methods: {
    // 轮播图
    // swiper() {
    //     new Swiper(".swiper-container", {
    //         pagination: { el: ".swiper-pagination" },
    //         loop: true,
    //         autoplay: {
    //             disableOnInteraction: false,
    //             delay: 2000,
    //         },
    //     });
    // },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;

  .img {
    width: 100%;
  }
}
</style>
